import React, { useEffect } from 'react';
import './App.scss';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';

// Importing components
import Experiences from './components/Experiences';
import FleetStatusDashboard from './components/Projects/FleetStatusDashboard';
import WebOrderUX from './components/Projects/WebOrderUX';
import RoadsideReimagined from './components/Projects/RoadsideReimagined';
import IoTAppDevelopment from './components/Projects/IoTAppDevelopment';
import ATSResume from './components/Projects/ATSResume';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { Analytics } from "@vercel/analytics/react"

// Importing images
import logo from './images/logo.svg';
import image1 from './images/image1.jpg';
import image2 from './images/medicine.png';
import image3 from './images/roadside.png';
import image4 from './images/code.png';
import image5 from './images/image5.png';

const App: React.FC = () => {
  const imageList = [
    {
      src: image1,
      alt: 'Description of Image 1',
      projectName: 'Robotics PM',
      companyName: 'ZEBRA TECHNOLOGIES',
      path: '/fleet-status-dashboard'
    },
    {
      src: image2,
      alt: 'Description of Image 2',
      projectName: 'Web Order UX',
      companyName: 'CIGNA',
      path: '/web-order-ux'
    },
    {
      src: image3,
      alt: 'Description of Image 3',
      projectName: 'Roadside Reimagined',
      companyName: 'PITSOP',
      path: '/roadside-reimagined'
    },
    {
      src: image4,
      alt: 'Description of Image 4',
      projectName: 'IoT App Development',
      companyName: 'ONSEMI',
      path: '/iot-app-development'
    },
    {
      src: image5,
      alt: 'Description of Image 5',
      projectName: 'AI Job-Search',
      companyName: 'QUICKCRUIT',
      path: '/quickcruit'
    },
    // ... add more images as needed
  ];


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router>
      <div className="App">

        <Analytics /> {/* This line includes the Analytics component */}
        
        <header className="App-header">
          <div className="container">
            <div className="navigation-bar">
              <Link to="/">
                <img src={logo} alt="Logo" className="logo" />
              </Link>
              <Link to="/contact" className="button-link">
                <button className="button">GET IN TOUCH</button>
              </Link>
            </div>
          </div>
        </header>
        <Routes>
          <Route path="/" element={
            <>
              <main className="main">
                <h1 style={{ fontSize: '30px', fontFamily: 'Amiri, sans-serif', color: '#333333', fontWeight: 'normal' }}>
                  Hi there, I'm Nilay. Put simply, I love building things.
                </h1>
                <h2 style={{ fontWeight: 'bold', fontFamily: 'Amiri, sans-serif', fontSize: '30px' }}>
                  Here's (most of) my work so far.
                </h2>
              </main>
              <div className="selected-work">
                <h2>SELECTED WORK</h2>
              </div>
              <Experiences images={imageList} />
            </>
          } />
          <Route path="/quickcruit" element={<ATSResume />} />
          <Route path="/fleet-status-dashboard" element={<FleetStatusDashboard />} />
          <Route path="/web-order-ux" element={<WebOrderUX />} />
          <Route path="/roadside-reimagined" element={<RoadsideReimagined />} />
          <Route path="/iot-app-development" element={<IoTAppDevelopment />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/personal-portfolio" element={<Navigate to="/" replace />} />
          <Route path="*" element={<div>Page not found - Are you sure the URL is correct?</div>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
