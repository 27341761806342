import React, { useEffect } from 'react';
import styles from './ATSResume.module.scss'; // Ensure this points to your module SCSS file
import { useInView } from 'react-intersection-observer'; // Assuming it's already installed
import Picture from '../../images/WorkInProgress.png';
import { Analytics } from '@vercel/analytics/react';


const ATSResume: React.FC = () => {
  const [infoRef, infoInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.projectPage}>
      
      <Analytics /> {/* This includes the Analytics component in your project page */}

      <div className={styles.contentContainer}>
        <h1>ATS Resume</h1>
        <div ref={infoRef} className={`${styles.infoSection} ${infoInView ? styles.fadeIn : ''}`}>
          <div className={styles.infoColumn}>
            <div className={styles.title}>CLIENT</div>
            <div className={styles.detail}>SaaS Startup</div>
          </div>
          <div className={styles.infoColumn}>
            <div className={styles.title}>ROLE</div>
            <div className={styles.detail}>Founder | Full-stack Developer</div>
          </div>
          <div className={styles.linkColumn}>
            <div className={styles.title}>LINK</div>
            <div className={styles.detail}>
              <a href="https://quickcruit.ai/" target="_blank" rel="noopener noreferrer">
                quickcruit.ai
              </a>
            </div>
          </div>
        </div>
        <img src={Picture} alt="Construction" className={styles.image}/>
        <p className={styles.constructionFootnote}>
          HANG TIGHT! WE'RE COOKING UP SOMETHING AWESOME
          {/* <span className={styles.emoji}>
            <img 
              src="https://cdn3.emoji.gg/emojis/4233-chefskiss.png" 
              alt="Chef's Kiss Emoji" 
            />
          </span> */}
        </p>
      </div>
    </div>
  );
};

export default ATSResume;
