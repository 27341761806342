import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './WebOrderUX.module.scss'; // Updated import to use module.scss
import WebPortal from '../../images/expressScripts.jpg'; 
import image1 from '../../images/previousState.png'; 
import HeatMap from '../../images/heatmap.png';
import Day12 from '../../images/Day12.png'; 
import Day46 from '../../images/Day46.png';
import Day7 from '../../images/Day7.png';
import Delivered from '../../images/Delivered.png';
import { Analytics } from '@vercel/analytics/react';


const WebOrderUX: React.FC = () => {
  const [aboutRef, aboutInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [problemStatementRef, problemStatementInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [collaborationRef, collaborationInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [trackerEnhancementRef, trackerEnhancementInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [updatedTracker, updatedTrackerInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [conclusionRef, conclusionInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.projectPage}>
      
      <Analytics/>

      <div className={styles.contentContainer}>
        <h1>Web Order UX</h1>
        <div className={styles.infoSection}>
          <div className={styles.infoColumn}>
            <div className={styles.title}>CLIENT</div>
            <div className={styles.detail}>Cigna</div>
          </div>
          <div className={styles.infoColumn}>
            <div className={styles.title}>ROLE</div>
            <div className={styles.detail}>Digital Product Management Intern</div>
          </div>
          <div className={styles.linkColumn}>
            <div className={styles.title}>LINK</div>
            <div className={styles.detail}>
              <a href="https://express-scripts.com/" target="_blank" rel="noopener noreferrer">
                express-scripts.com/
              </a>
            </div>
          </div>
        </div>

        <img src={WebPortal} alt="Front Display" className={styles.image} />

        <div ref={aboutRef} className={`${styles.paragraphSection} ${aboutInView ? styles.fadeIn : ''}`}>
          <h2>About</h2>
          <p>
            Express Script's industry-leading web order portal serves over 100 million Americans, streamlining medication 
            management with convenient, 24/7 accessible prescription ordering and affordable copays. Medications are reliably 
            delivered to users' doorsteps, supported by dedicated pharmacists and service representatives ready to assist with 
            health and insurance queries, enhancing wellness nationwide.
          </p>
          <p>
            During my term as a Digital Product Management Intern at Express Scripts, I led the product team to improve the prescription 
            order status experience, ensuring clear and accessible updates throughout the prescription ordering process. The 
            initiative greatly enhanced transparency with easy-to-understand steps, significantly improved user satisfaction and 
            trust during the waiting period.
          </p>
        </div>

        <figure>
          <img src={image1} alt="Previous State" className={styles.image} />
          <figcaption>Previous state of the Web Order Tracker</figcaption>
        </figure>

        <div ref={problemStatementRef} className={`${styles.paragraphSection} ${problemStatementInView ? styles.fadeIn : ''}`}>
          <h2>Identified the Problem Statement</h2>
          <p>
            I was assigned to the Web-order Experience team, where my role was to identify pain points of our customers and collaborate
            with various teams, including Customer Outreach, UI/UX, Pharmacy, and Development, to enhance the overall web-order experience.
          </p>
          <p>
            To delve deeper into customer experiences, I collaborated closely with the Customer Outreach team, analyzing over 2600+ 
            reviews using Natural Language Processing (NLP). This approach allowed us to detect recurring themes and pinpoint the most 
            pressing concerns by identifying patterns in the feedback. In our quest to prioritize and address the most critical features, 
            we employed heatmap analysis to gather detailed data on customer UX interactions.
          </p>
          <p>
            Our comprehensive analysis led us to a significant finding: customers desired greater transparency during the 'processing' 
            stage of their prescription orders. We discovered that, often, customers were running out of crucial medication due to 
            unexpected order cancellations, typically resulting from unanticipated requirements such as a doctor's visit. This crucial 
            insight was not clearly communicated until after the order was cancelled, leading to frustration. These transparency 
            challenges, most prominently highlighted through NLP insights, became a focal point for our subsequent enhancements.
          </p>
        </div>

        <figure>
          <img src={HeatMap} alt="HeatMap Analysis" className={styles.image} />
          <figcaption>HeatMap Analysis</figcaption>
        </figure>

        <div ref={collaborationRef} className={`${styles.paragraphSection} ${collaborationInView ? styles.fadeIn : ''}`}>
          <h2>Collaborated with Cross-functional teams</h2>
          <p>
            After identifying the core problems and developing a clear problem statement, I collaborated closely with the Customer 
            Outreach and Pharmacy teams. This collaboration deepened my understanding of the prescription fulfillment process. 
            Consequently, I devised a solution to enhance transparency within the tracker, incorporating additional stages and 
            clearly displaying the status updates of prescription orders.
          </p>
          <p>
            Following the development of this solution, I presented it to my manager and created Figma wireframes to bring my 
            vision for an improved web-order experience to life. I then worked alongside the UI/UX team to ensure the design 
            aligned with our branding and learned about the critical role of user experience in product development. Finally, I 
            passed our refined tracker to the Development team for implementation and prepared it for beta testing with a select 
            group of internal and external stakeholders.
          </p>
        </div>

        <div ref={trackerEnhancementRef} className={`${styles.paragraphSection} ${trackerEnhancementInView ? styles.fadeIn : ''}`}>
          <h2>Enhancing Transparency in the Legacy Tracker</h2>
          <p>
            The previous tracker struggled with transparency, leaving users unclear on the progress of their orders. Essential 
            updates, such as doctor's consultations or prescription confirmations, were obscured in the 'Order Received/Delayed' phase. 
            This often resulted in unforeseen cancellations and user dissatisfaction. We are now dedicated to ensuring a 
            clearer and more communicative tracking journey.
          </p>
        </div>

        <div className={styles.projectSummaryText}>
          Revamped Tracker: Seamlessly Integrated with Customer-Driven Insights.
        </div>

        <figure>
          <img src={Day12} alt="Day 12" className={styles.image} />
          <figcaption>Status for Day 1 - 2</figcaption>
        </figure>

        <figure>
          <img src={Day46} alt="Day 46" className={styles.image} />
          <figcaption>Status for Day 3 - 6</figcaption>
        </figure>

        <figure>
          <img src={Day7} alt="Day 7" className={styles.image} />
          <figcaption>Status for Day 7</figcaption>
        </figure>

        <figure>
          <img src={Delivered} alt="Delivered" className={styles.image} />
          <figcaption>Prescription Delivered 🎉</figcaption>
        </figure>

        <div ref={updatedTracker} className={`${styles.paragraphSection} ${updatedTrackerInView ? styles.fadeIn : ''}`}>
          <p>
            The updated tracker enhances the customer experience by providing detailed information about the status of their prescription. 
            It highlights essential details in bold, ensuring customers can quickly identify any necessary actions or information. 
            Additionally, each stage of the process is accompanied by visuals that effectively tell the story of their prescription's 
            journey, offering accurate and timely updates.
          </p>
        </div>

        <div ref={conclusionRef} className={`${styles.paragraphSection} ${conclusionInView ? styles.fadeIn : ''}`}>
          <h2>Conclusion</h2>
          <p>
            My internship as a Digital Product Manager Intern at Express Scripts, a Cigna subsidiary, was an incredibly rewarding experience. 
            Being my first corporate Product Management role, I was thrilled by the warm welcome from the team, which helped me quickly 
            assimilate into the company's culture. Immediately, I was tasked with enhancing the web order experience, a challenging yet 
            exciting opportunity, especially considering the tight 12-week timeframe. My focus was to make a positive impact while absorbing 
            as much knowledge as possible.
          </p>
          <p>
            One of the highlights of my internship was working on the UX, where I focused on ensuring clear and sensitive
            communication in our web tracker updates. This was a gratifying experience, especially seeing the positive response 
            from the team to the work I contributed. Additionally, collaborating with the pharmacy team provided me with invaluable 
            insights into the intricacies of prescription processing.
          </p>
          <p>
            Overall, this internship was a fantastic learning and contributing experience, laying a solid foundation for my future in 
            product management in a corporate setting.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WebOrderUX;
