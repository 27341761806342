import React from 'react';
import './Footer.scss';
// import { Icon } from '@iconify/react';
// import zoomIcon from '@iconify/icons-logos/zoom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons';
import logo from '../images/logo_white.svg'; // Import the logo

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-brand">
        <a href="/" aria-current="page" className="footer-logo w-inline-block w--current">
          <img src={logo} alt="Logo" />
        </a>
      </div>
      <div className="footer-details">
        <div className="footer-section">
          <h3>SPEAK</h3>
          <a href="mailto:nilay.patelpm@gmail.com" className="text-white">nilay.patelpm@gmail.com</a>
          {/* <div className="text-white">505.862.6771</div> */}
          <div className="text-white">
            <a href="tel:+15058626771" style={{ color: 'inherit', textDecoration: 'none' }}>
              505.862.6771
            </a>
          </div>
        </div>
        <div className="footer-section">
          <h3>VISIT</h3>
          <div className="text-white">Currently: San Francisco, California</div>
        </div>
        <div className="footer-section">
          <h3>CONNECT</h3>
          <div className="social-icons">
            <a href="https://www.instagram.com/nilay.humor/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="fa-icon" />
            </a>
            <a href="https://www.linkedin.com/in/npatel84/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedinIn} className="fa-icon" />
            </a>
            <a href="https://github.com/npatel84" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} className="fa-icon" />
            </a>
            {/* <a href="http://nilay.johnwack.com/" target="_blank" rel="noopener noreferrer">
              <Icon icon={zoomIcon} className="zoom-icon" />
            </a> */}
          </div>
        </div>
      </div>
      <div className="footer-subtext">
        <p>© Copyright Nilay Patel 2024 All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
