import React, { useEffect } from 'react';
import styles from './IoTAppDevelopment.module.scss'; // Ensure this points to your module SCSS file
import { useInView } from 'react-intersection-observer'; // Assuming it's already installed
import Picture from '../../images/WorkInProgress.png';
import { Analytics } from '@vercel/analytics/react';


const IOTAppDevelopement: React.FC = () => {
  const [infoRef, infoInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.projectPage}>

      <Analytics/>
      
      <div className={styles.contentContainer}>
        <h1>IOT App Developement</h1>
        <div ref={infoRef} className={`${styles.infoSection} ${infoInView ? styles.fadeIn : ''}`}>
          <div className={styles.infoColumn}>
            <div className={styles.title}>CLIENT</div>
            <div className={styles.detail}>Onsemi</div>
          </div>
          <div className={styles.infoColumn}>
            <div className={styles.title}>ROLE</div>
            <div className={styles.detail}>Mobile Software Engineer Intern</div>
          </div>
          <div className={styles.linkColumn}>
            <div className={styles.title}>LINK</div>
            <div className={styles.detail}>
              <a href="https://play.google.com/store/apps/details?id=com.onsemi.RSL10Smartshot&hl=en_US&gl=US&pli=1" target="_blank" rel="noopener noreferrer">
                play.google.com/RSL10SmartShotCamera
              </a>
            </div>
          </div>
        </div>
        <img src={Picture} alt="Construction" className={styles.image}/>
        <p className={styles.constructionFootnote}>
          HANG TIGHT! WE'RE COOKING UP SOMETHING AWESOME
          {/* <span className={styles.emoji}>
            <img 
              src="https://cdn3.emoji.gg/emojis/4233-chefskiss.png" 
              alt="Chef's Kiss Emoji" 
            />
          </span> */}
        </p>
      </div>
    </div>
  );
};

export default IOTAppDevelopement;
