import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.scss';
import { Analytics } from "@vercel/analytics/react"

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    portfolioLink: '',
    message: '',
  });

  const [submitStatus, setSubmitStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('Sending...');
    console.log('Form submitted', formData); // Debug: Check form data

    try {
      const result = await emailjs.send('service_id', 'template_id', formData, 'QtHBJSOo1l5xiR-4t');
      console.log('Email successfully sent!', result.text);
      setSubmitStatus('Email successfully sent!');
      setFormData({
        name: '',
        email: '',
        portfolioLink: '',
        message: '',
      }); // Optional: Reset form
    } catch (error) {
      console.error('Failed to send email:', error.text);
      setSubmitStatus('Failed to send email.');
    }
  };

  return (
    <div className="contact-form-container">
      
      <Analytics /> {/* This line includes the Analytics component */}

      <h2>Get in touch</h2>
      <div className="separator"></div>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="name">NAME *</label>
          <input type="text" id="name" name="name" required onChange={handleChange} placeholder="Enter your name" />
        </div>
        <div className="form-group">
          <label htmlFor="email">EMAIL ADDRESS *</label>
          <input type="email" id="email" name="email" required onChange={handleChange} placeholder="eg: you@yoursite.com" />
        </div>
        <div className="form-group">
          <label htmlFor="portfolioLink">PORTFOLIO/LINKEDIN LINK *</label>
          <input type="url" id="portfolioLink" name="portfolioLink" required onChange={handleChange} placeholder="https://" />
        </div>
        <div className="form-group">
          <label htmlFor="message">MESSAGE *</label>
          <textarea id="message" name="message" required onChange={handleChange} placeholder="Please type your message here" />
        </div>
        <button type="submit" className="submit-btn">Submit</button>
      </form>
      {submitStatus && <p>{submitStatus}</p>}
    </div>
  );
};

export default Contact;
