// FleetStatusDashboard.tsx

import React, { useEffect } from 'react';
import styles from './FleetStatusDashboard.module.scss';
import { useInView } from 'react-intersection-observer';
import projectImage from '../../images/Dev1.png';
import FetchCore from '../../images/FetchCore.jpg';
import { Analytics } from '@vercel/analytics/react';

const FleetStatusDashboard: React.FC = () => {
  const [aboutRef, aboutInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [projectSummaryRef, projectSummaryInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [testimonialRef, testimonialInView] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [conclusionRef, conclusionInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.projectPage}>

      <Analytics /> {/* This includes the Analytics component in your project page */}

      <div className={styles.contentContainer}>
        <h1>Robotics PM</h1>
        <div className={styles.infoSection}>
          <div className={styles.infoColumn}>
            <div className={styles.title}>CLIENT</div>
            <div className={styles.detail}>Zebra Technologies</div>
          </div>
          <div className={styles.infoColumn}>
            <div className={styles.title}>ROLE</div>
            <div className={styles.detail}>Robotics Product Management Intern</div>
          </div>
          <div className={styles.linkColumn}>
            <div className={styles.title}>LINK</div>
            <div className={styles.detail}>
              <a href="https://www.zebra.com/us/en/software/zebra-symmetry.html" target="_blank" rel="noopener noreferrer">
                zebra.com/zebra-symmetry/
              </a>
            </div>
          </div>
        </div>

        <img src={FetchCore} alt="Fleet Status Dashboard display" className={styles.image} />

        <div ref={aboutRef} className={`${styles.paragraphSection} ${aboutInView ? styles.fadeIn : ''}`}>
          <h2>About</h2>
          <p>Fetch Core is an advanced Fleet Management System Software developed for Autonomous Mobile Robots (AMRs) at Fetch Robotics, which is now a part of Zebra Technologies. As a cornerstone application, Fetch Core orchestrates the operation of an impressive fleet, engaging over 150 customers and managing approximately 60,000 AMRs.</p>
          <p>In my role as a Robotics Product Management Intern, I led the product team in strategizing, implementing, and launching this innovative product. Collaborating with stakeholders such as the UI/UX Design team, Software Developers, Project Managers, and users, I was integral in shaping the product's design with Figma and developing user-centric dashboards in Looker Studio with LookML and HTML, leveraging Snowflake databases.</p>
        </div>

        <div ref={projectSummaryRef} className={`${styles.projectSummaryText} ${projectSummaryInView ? styles.fadeIn : ''}`}>
          Effortless Oversight with a Glance: The All-in-One Fleet Status Dashboard for Peak Operational Efficiency.
        </div>

        <figure>
          <img src={projectImage} alt="Fully-refreshed Fleet Status Dashboard" className={styles.image} />
          <figcaption>A fully-refreshed view of the Fleet Status Dashboard</figcaption>
        </figure>

        <div ref={testimonialRef} className={`${styles.testimonialSection} ${testimonialInView ? styles.fadeIn : ''}`}>
          <div className={styles.testimonialQuote}>
            “This updated dashboard is exactly what we've been waiting for. It's intuitive, comprehensive, and has significantly enhanced our operational workflow. A game-changer for sure!”
          </div>
          <div className={styles.testimonialAuthor}>- JORDAN SMITH, OPERATIONS MANAGER</div>
        </div>

        <div ref={conclusionRef} className={`${styles.paragraphSection} ${conclusionInView ? styles.fadeIn : ''}`}>
          <h2>Conclusion</h2>
          <p>Working at Zebra was an extraordinarily challenging yet rewarding experience. I joined the team as a Product Management Intern and helped build a newly-refreshed dashboard that customers enjoy using. When my journey with the company concluded, I took with me a wealth of skills and knowledge that came from working at a high-growth startup with products that are built by a few and loved by many.</p>
        </div>
      </div>
    </div>
  );
};

export default FleetStatusDashboard;
